import { Link } from "gatsby"
import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import "./merci.scss"

const Merci = () => (
  <Layout activePage="nous-trouver">
    <SEO
      title="Merci"
      description="La Consigne GreenGo est une app' mobile permettant d’emprunter facilement des emballages réutilisables et consignés dans tout type de structures : restaurants, cafétérias d’entreprises et grande distribution."
      keywords={["consigne", "emballage", "collecteur", "restauration"]}
    />
    <section className="merci section-padding container">
      <h1 className="heading heading--1">Merci pour votre intérêt !</h1>
      <p>Un membre de l'équipe La Consigne GreenGo reviendra vers vous très rapidement.</p>
      <Link
        to="/"
        className="link button button--color-pink link--back-to-blog"
      >
        Revenir à l'accueil
      </Link>
    </section>
  </Layout>
)

export default Merci
